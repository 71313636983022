import MenuIcon from '@mui/icons-material/Menu';
import {
  AppBar,
  Box,
  Button,
  Container,
  createTheme,
  CssBaseline,
  IconButton,
  Menu,
  MenuItem,
  Paper,
  Snackbar,
  ThemeProvider,
  Toolbar,
  Typography
} from '@mui/material';
import { debounce } from 'lodash';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Route, BrowserRouter as Router, Routes, useNavigate } from 'react-router-dom';
import Calendar from './components/Calendar';
import DailyJoke from './components/DailyJoke';
import InstallPWA from './components/InstallPWA';
import ReservationManager from './components/ReservationManager';
import SyncIndicator from './components/SyncIndicator';
import UserSelector from './components/UserSelector';
import UserSettings from './components/UserSettings';
import { api, Reservation } from './services/api';
import { offlineStorage } from './services/offlineStorage';
import WebSocketService from './services/WebSocketService';
import './App.css'; 

const getGreeting = (selectedUser: string) => {
  const now = new Date();
  const hour = now.getHours();
  const month = now.getMonth() + 1; // getMonth() returns 0-11

  // Define union types for timeOfDay and season
  type TimeOfDay = 'morning' | 'afternoon' | 'evening';
  type Season = 'winter' | 'spring' | 'summer' | 'autumn';

  let timeOfDay: TimeOfDay;
  if (hour < 12) {
    timeOfDay = 'morning';
  } else if (hour < 18) {
    timeOfDay = 'afternoon';
  } else {
    timeOfDay = 'evening';
  }

  let season: Season;
  if (month === 12 || month <= 2) {
    season = 'winter';
  } else if (month <= 5) {
    season = 'spring';
  } else if (month <= 8) {
    season = 'summer';
  } else {
    season = 'autumn';
  }

  // Define the greetings object with the correct types
  const greetings: Record<TimeOfDay, Record<Season, string>> = {
    morning: {
      winter: `Tervetuloa, ${selectedUser}! Toivottavasti talviaamusi on rauhallinen. ❄️`,
      spring: `Tervetuloa, ${selectedUser}! Toivottavasti kevätaamusi on täynnä iloa. 🌸`,
      summer: `Tervetuloa, ${selectedUser}! Toivottavasti kesäaamusi on aurinkoinen. ☀️`,
      autumn: `Tervetuloa, ${selectedUser}! Toivottavasti syysaamusi on kaunis. 🍂`,
    },
    afternoon: {
      winter: `Tervetuloa, ${selectedUser}! Toivottavasti talvipäiväsi on mukava. ⛄`,
      spring: `Tervetuloa, ${selectedUser}! Toivottavasti kevätpäiväsi on energinen. 🌼`,
      summer: `Tervetuloa, ${selectedUser}! Toivottavasti kesäpäiväsi on seikkailun täynnä. 🌞`,
      autumn: `Tervetuloa, ${selectedUser}! Toivottavasti syyspäiväsi on rauhoittava. 🍁`,
    },
    evening: {
      winter: `Tervetuloa, ${selectedUser}! Toivottavasti talvi-iltasi on lämmin. 🔥`,
      spring: `Tervetuloa, ${selectedUser}! Toivottavasti kevätiltasi on rentouttava. 🌷`,
      summer: `Tervetuloa, ${selectedUser}! Toivottavasti kesäiltasi on täynnä ystäviä. 🌙`,
      autumn: `Tervetuloa, ${selectedUser}! Toivottavasti syysiltasi on tunnelmallinen. 🍂`,
    },
  };

  // Now TypeScript knows that timeOfDay and season are valid keys
  return greetings[timeOfDay][season];
};

interface AppContentProps {
  navigate: (path: string) => void;
}

const AppContent: React.FC<AppContentProps> = ({ navigate }) => {
  const theme = createTheme({
    palette: {
      primary: {
        main: '#8A2BE2', // Purple for the top bar
      },
      background: {
        default: '#ecf0f1', // Set your desired background color here
      },
      secondary: {
        main: '#90EE90', // Light Green for primary buttons
      },
      error: {
        main: '#FFA07A', // Soft Orange for error or cancel buttons
      },
      warning: {
        main: '#FFFFE0', // Pastel Yellow for warning or secondary actions
      },
      info: {
        main: '#87CEFA', // Light Blue for informational elements
      },
    },
  });

  const [reservations, setReservations] = useState<Reservation[]>([]);
  const [selectedDate, setSelectedDate] = useState<Date | null>(null);
  const [selectedUser, setSelectedUser] = useState<string | null>(null);
  const [calendarKey, setCalendarKey] = useState(0);
  const [isOnline, setIsOnline] = useState(navigator.onLine);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [syncStatus, setSyncStatus] = useState<'syncing' | 'synced' | 'error'>('synced');
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [isUpdateAvailable, setIsUpdateAvailable] = useState(false);
  const [refreshing, setRefreshing] = useState(false);

  const WS_URL = process.env.REACT_APP_WS_URL || 'wss://parkki.liulia.net/ws';

  const fetchReservations = useCallback(async () => {
    if (isOnline) {
      try {
        setSyncStatus('syncing');
        console.log('Fetching reservations...');
        const fetchedReservations = await api.getReservations();
        console.log('Fetched reservations:', fetchedReservations);
        setReservations(fetchedReservations);
        setCalendarKey(prev => prev + 1);
        offlineStorage.saveReservations(fetchedReservations);
        setSyncStatus('synced');
      } catch (error) {
        console.error('Error fetching reservations:', error);
        setSyncStatus('error');
        setSnackbarMessage('Varausten hakeminen epäonnistui. Yritä myöhemmin uudelleen.');
        setSnackbarOpen(true);
      }
    } else {
      const offlineReservations = offlineStorage.getReservations();
      console.log('Offline reservations:', offlineReservations);
      setReservations(offlineReservations);
      setCalendarKey(prev => prev + 1);
    }
  }, [isOnline, setReservations, setCalendarKey, setSyncStatus, setSnackbarMessage, setSnackbarOpen]);

  const debouncedFetchReservationsRef = useRef(debounce((isOnline: boolean, fetchReservations: () => Promise<void>) => {
    if (isOnline) {
      fetchReservations();
    }
  }, 500));

  const debouncedFetchReservations = useCallback(() => {
    debouncedFetchReservationsRef.current(isOnline, fetchReservations);
  }, [isOnline, fetchReservations]);

  useEffect(() => {
    let updateInterval: number;

    const checkForUpdates = () => {
      if ('serviceWorker' in navigator && navigator.serviceWorker.controller) {
        navigator.serviceWorker.controller.postMessage({ type: 'CHECK_FOR_UPDATES' });
      }
    };

    if ('serviceWorker' in navigator) {
      navigator.serviceWorker.ready.then((registration) => {
        // Check for updates on load
        registration.update();

        // Set up an interval to check for updates periodically
        updateInterval = window.setInterval(() => {
          registration.update();
        }, 60 * 60 * 1000); // Every hour
      });
    }

    const handleVisibilityChange = () => {
      if (document.visibilityState === 'visible') {
        checkForUpdates();
        // Reconnect WebSocket if not connected
        if (
          WebSocketService.readyState !== WebSocket.OPEN &&
          WebSocketService.readyState !== WebSocket.CONNECTING
        ) {
          console.log('Reconnecting WebSocket...');
          WebSocketService.connect();
        }
        // Re-fetch reservations to update data
        if (isOnline) {
          debouncedFetchReservations();
        }
      }
    };

    document.addEventListener('visibilitychange', handleVisibilityChange);

    // Check for updates periodically while the app is open
    const intervalId = setInterval(checkForUpdates, 5 * 60 * 1000); // Every 5 minutes

    // Error handling
    const handleError = (event: ErrorEvent) => {
      console.error('Uncaught error:', event.error);
      requestHardReload();
    };

    window.addEventListener('error', handleError);

    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
      clearInterval(intervalId);
      window.removeEventListener('error', handleError);
      if (updateInterval) {
        window.clearInterval(updateInterval);
      }
    };
  }, [isOnline, debouncedFetchReservations]);

  function requestHardReload() {
    if ('serviceWorker' in navigator && navigator.serviceWorker.controller) {
      navigator.serviceWorker.controller.postMessage({ type: 'HARD_RELOAD' });
    }
  }

  const handleConflict = useCallback(async (conflict: Reservation): Promise<boolean> => {
    return new Promise<boolean>((resolve) => {
      const serverDate = new Date(conflict.date).toLocaleDateString();
      const options = ["Käytä palvelimen varausta", "Käytä paikallisen laitteen varausta"];
      const choice = window.confirm(`Varausristiriita päivämäärälle ${serverDate}. \nPalvelimen varaus: ${conflict.user}\n\n${options.join("\n")}`);

      if (choice) {
        const useServerReservation = options[0] === "Käytä palvelimen varausta";
        if (useServerReservation) {
          // Resolve conflict in favor of the server version
          resolve(true);
        } else {
          // Retry adding/updating the local reservation
          api.syncReservationWithAction(conflict, 'update') // Assuming the local reservation object is available
            .then(() => resolve(true))
            .catch(error => {
              console.error('Error syncing local reservation:', error);
              resolve(false);
            });
        }
      } else {
        resolve(false); // Conflict not resolved
      }
    });
  }, []);

  const handleWebSocketMessage = useCallback((message: any) => {
    console.log('Received WebSocket message:', message);
    if (message.type === 'initialData' || message.type === 'reservations') {
      setReservations(message.data.map((r: any) => ({ ...r, date: new Date(r.date) })));
      setCalendarKey(prev => prev + 1);
    } else if (message.type === 'pong') {
      console.log('Received pong from server');
    }
  }, []);

  const syncOfflineData = useCallback(async () => {
    const offlineReservations = offlineStorage.getReservations();
    const pendingActions = offlineStorage.getPendingActions();

    if (offlineReservations.length > 0 || pendingActions.length > 0) {
      try {
        setSyncStatus('syncing');

        // First, sync the reservations
        if (offlineReservations.length > 0) {
          const { reservations, conflicts } = await api.syncReservations(offlineReservations);
          setReservations(reservations);
          setCalendarKey(prev => prev + 1);

          if (conflicts.length > 0) {
            // Handle conflicts one by one
            for (const conflict of conflicts) {
              if (await handleConflict(conflict)) { // Only proceed if conflict is resolved
                const updatedReservations = await api.getReservations();
                setReservations(updatedReservations);
                setCalendarKey(prev => prev + 1);
              } else {
                // Stop syncing if a conflict is not resolved
                throw new Error("Conflict resolution failed");
              }
            }
          }
        }

        // Then, process pending actions - Only if all conflicts were resolved!
        for (const action of pendingActions) {
          switch (action.type) {
            case 'ADD_RESERVATION':
              await api.addReservation(action.payload);
              break;
            case 'UPDATE_RESERVATION':
              await api.syncReservationWithAction(action.payload, 'update');
              break;
            case 'DELETE_RESERVATION':
              await api.deleteReservation(action.payload.id);
              break;
          }
        }

        // Clear offline data after successful sync
        offlineStorage.clear();

        // Fetch latest data from server
        debouncedFetchReservations();

        setSyncStatus('synced');
        setSnackbarMessage('Offline-muutokset synkronoitu onnistuneesti.');
        setSnackbarOpen(true);
      } catch (error) {
        console.error('Error syncing offline data:', error);
        setSnackbarMessage('Offline-tietojen synkronointi epäonnistui. Yritä myöhemmin uudelleen.');
        setSnackbarOpen(true);
        setSyncStatus('error');
      }
    } else {
      setSyncStatus('synced');
    }
  }, [handleConflict, debouncedFetchReservations]);

  const handleOnline = useCallback(() => {
    setIsOnline(true);
    setSnackbarMessage('Olet taas online-tilassa. Synkronoidaan tietoja...');
    setSnackbarOpen(true);
    syncOfflineData();
  }, [syncOfflineData]);

  const handleOffline = useCallback(() => {
    setIsOnline(false);
    setSnackbarMessage('Olet offline-tilassa. Muutokset tallennetaan paikallisesti.');
    setSnackbarOpen(true);
  }, []);

  useEffect(() => {
    WebSocketService.initialize(WS_URL, handleWebSocketMessage);
    WebSocketService.connect();

    const pingInterval = setInterval(() => {
      if (WebSocketService.readyState === WebSocket.OPEN) {
        WebSocketService.ping();
      }
    }, 30000); // Ping every 30 seconds

    const fetchInterval = setInterval(() => {
      if (isOnline) {
        debouncedFetchReservations();
      }
    }, 60000); // Fetch every 60 seconds if online

    window.addEventListener('online', handleOnline);
    window.addEventListener('offline', handleOffline);

    const savedUser = localStorage.getItem('selectedUser');
    if (savedUser) {
      setSelectedUser(savedUser);
    }

    // Initial fetch
    debouncedFetchReservations();

    return () => {
      clearInterval(pingInterval);
      clearInterval(fetchInterval);
      WebSocketService.close();
      window.removeEventListener('online', handleOnline);
      window.removeEventListener('offline', handleOffline);
    };
  }, [handleWebSocketMessage, handleOnline, handleOffline, debouncedFetchReservations, isOnline, WS_URL]);

  useEffect(() => {
    const handleControllerChange = () => {
      if (refreshing) return;
      setRefreshing(true);
      window.location.reload();
    };

    const handleUpdateAvailable = (event: MessageEvent) => {
      if (event.data && event.data.type === 'UPDATE_AVAILABLE') {
        setIsUpdateAvailable(true);
      }
    };

    navigator.serviceWorker.addEventListener('controllerchange', handleControllerChange);
    navigator.serviceWorker.addEventListener('message', handleUpdateAvailable);

    // Check for updates when the component mounts
    if (navigator.serviceWorker.controller) {
      navigator.serviceWorker.controller.postMessage({ type: 'CHECK_FOR_UPDATES' });
    }

    return () => {
      navigator.serviceWorker.removeEventListener('controllerchange', handleControllerChange);
      navigator.serviceWorker.removeEventListener('message', handleUpdateAvailable);
    };
  }, [refreshing]);

  const handleUpdateClick = useCallback(() => {
    if (navigator.serviceWorker.controller) {
      navigator.serviceWorker.controller.postMessage({ type: 'SKIP_WAITING' });
      setRefreshing(true);
    }
  }, []);

  const handleUpdateLater = useCallback(() => {
    setIsUpdateAvailable(false);
  }, []);

  const UpdateNotification: React.FC = () => {
    if (!isUpdateAvailable) return null;

    return (
      <Box sx={{ mb: 2, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <Typography variant="body2">Uusi versio saatavilla!</Typography>
        <Box>
          <Button
            variant="contained"
            onClick={handleUpdateClick}
            sx={{
              mr: 1,
              backgroundColor: theme => theme.palette.secondary.main,
              color: theme => theme.palette.secondary.contrastText,
              '&:hover': {
                backgroundColor: theme => theme.palette.secondary.dark,
              }
            }}
          >
            Päivitä nyt
          </Button>
          <Button
            variant="outlined"
            onClick={handleUpdateLater}
            sx={{
              color: theme => theme.palette.secondary.main,
              borderColor: theme => theme.palette.secondary.main,
              '&:hover': {
                backgroundColor: theme => theme.palette.secondary.light,
              }
            }}
          >
            Myöhemmin
          </Button>
        </Box>
      </Box>
    );
  };

  const handleReservationChange = useCallback((changedReservation: Reservation | { id: string }, action: 'add' | 'update' | 'delete') => {
    setReservations(prevReservations => {
      let newReservations;
      switch (action) {
        case 'add':
        case 'update':
          newReservations = prevReservations.map(r =>
            r.id === (changedReservation as Reservation).id ? changedReservation as Reservation : r
          );
          if (action === 'add' && !newReservations.some(r => r.id === (changedReservation as Reservation).id)) {
            newReservations.push(changedReservation as Reservation);
          }
          break;
        case 'delete':
          newReservations = prevReservations.filter(r => r.id !== (changedReservation as { id: string }).id);
          break;
      }
      setCalendarKey(prev => prev + 1);
      return newReservations;
    });

    if (isOnline) {
      setSyncStatus('syncing');
      if (WebSocketService.readyState === WebSocket.OPEN) {
        WebSocketService.send({ type: 'reservationUpdate', data: { reservation: changedReservation, action } });
        setSyncStatus('synced');
      } else {
        setSyncStatus('synced');
      }
    } else {
      if (action === 'delete') {
        offlineStorage.saveAction({ type: 'DELETE_RESERVATION', payload: { id: (changedReservation as { id: string }).id } });
      } else {
        offlineStorage.saveReservation(changedReservation as Reservation, action);
      }
      setSyncStatus('synced');
    }
  }, [isOnline]);

  const handleDateSelect = (date: Date | null) => {
    console.log('Selected date:', date);
    setSelectedDate(date);
  };

  const handleUserSelect = (user: string) => {
    setSelectedUser(user);
    localStorage.setItem('selectedUser', user);
  };

  const handleChangeUser = () => {
    setSelectedUser(null);
    localStorage.removeItem('selectedUser');
  };

  const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <AppBar position="static" color="primary">
        <Toolbar variant="dense" sx={{ justifyContent: 'space-between' }}>
          <Typography variant="h6" component="div">
            P-APPI
          </Typography>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <SyncIndicator status={syncStatus} />
            <InstallPWA />
            {selectedUser && (
              <IconButton
                color="inherit"
                onClick={handleMenu}
                size="large"
                edge="end"
                aria-label="menu"
              >
                <MenuIcon />
              </IconButton>
            )}
            <Menu
              id="menu-appbar"
              anchorEl={anchorEl}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              open={Boolean(anchorEl)}
              onClose={handleClose}
            >
              <MenuItem onClick={() => { navigate('/settings'); handleClose(); }}>
                Asetukset
              </MenuItem>
              <MenuItem onClick={handleChangeUser}>
                Vaihda käyttäjä
              </MenuItem>
            </Menu>
          </Box>
        </Toolbar>
      </AppBar>
      <Container maxWidth="sm" sx={{ mt: 2, mb: 2 }}>
        <Routes>
          <Route path="/" element={
            <Paper elevation={0} sx={{ p: 2, border: '0.2px solid #000' }}>
              {selectedUser ? (
                <>
                  <Typography
                    variant="body1"
                    component="h1"
                    gutterBottom
                    sx={{ fontFamily: "'Lora', serif" }}
                  >
                    {getGreeting(selectedUser)}
                  </Typography>
                  <DailyJoke />
                  <UpdateNotification />
                  <Box
                    display="flex"
                    flexDirection="column"
                    alignItems="center"
                    sx={{
                      borderRadius: '10px',
                      boxShadow: '0px 2px 5px rgba(0, 0, 0, 0.1)',
                      overflow: 'hidden',
                    }}
                  >
                    <Box width="100%" maxWidth="300px" mb={2}>
                      <Calendar
                        key={calendarKey}
                        reservations={reservations}
                        onDateSelect={handleDateSelect}
                        selectedDate={selectedDate}
                      />
                    </Box>
                    <Box width="100%" maxWidth="300px" marginBottom="20px">
                      <ReservationManager
                        selectedDate={selectedDate}
                        reservations={reservations}
                        onReservationChange={handleReservationChange}
                        selectedUser={selectedUser}
                      />
                    </Box>
                  </Box>
                </>
              ) : (
                <UserSelector onUserSelect={handleUserSelect} />
              )}
            </Paper>
          } />
          <Route path="/settings" element={<UserSettings />} />
        </Routes>
      </Container>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={() => setSnackbarOpen(false)}
        message={snackbarMessage}
      />
      <div className="bottom-background"></div>
    </ThemeProvider>
  );
};

const AppContentWithNavigation: React.FC = () => {
  const navigate = useNavigate();
  return <AppContent navigate={navigate} />;
};

const App: React.FC = () => {
  return (
    <Router>
      <AppContentWithNavigation />
    </Router>
  );
};

export default App;
