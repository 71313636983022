import React, { useState, useEffect } from 'react';
import { Box, Typography, Modal } from '@mui/material';
import { api } from '../services/api';

interface Joke {
  id: number;
  text: string;
}

const DailyJoke: React.FC = () => {
  const [joke, setJoke] = useState<Joke | null>(null);
  const [showFullJoke, setShowFullJoke] = useState(false);

  useEffect(() => {
    const fetchJoke = async () => {
      try {
        const result = await api.fetchJokesWithCache(1);
        if (result && result.jokes.length > 0) {
          setJoke(result.jokes[0]);
        }
      } catch (error) {
        console.error('Error fetching joke:', error);
      }
    };

    fetchJoke();
  }, []);

  const handleOpenModal = () => setShowFullJoke(true);
  const handleCloseModal = () => setShowFullJoke(false);

  if (!joke) return null;

  const truncatedJoke = joke.text.length > 100 ? `${joke.text.substring(0, 100)}...` : joke.text;
  const needsReadMore = joke.text.length > 100;

  return (
    <Box sx={{ mt: 2, mb: 2 }}>
      <Typography 
        variant="body1" 
        component="blockquote"
        onClick={handleOpenModal} 
        sx={{ 
          cursor: 'pointer',
          fontFamily: "'Lora', serif",
          borderLeft: '4px solid #ccc',
          paddingLeft: 2,
          marginLeft: 0,
          fontStyle: 'italic'
        }}
      >
        "{truncatedJoke}"
        {needsReadMore && (
          <Typography 
            component="span" 
            sx={{ 
              fontWeight: 'bold', 
              fontStyle: 'normal',
              marginLeft: 1,
              cursor: 'pointer'
            }}
          >
            ...Lue lisää
          </Typography>
        )}
      </Typography>
      <Modal
        open={showFullJoke}
        onClose={handleCloseModal}
        aria-labelledby="joke-modal-title"
        aria-describedby="joke-modal-description"
      >
        <Box sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: 400,
          bgcolor: 'background.paper',
          border: '2px solid #000',
          boxShadow: 24,
          p: 4,
        }}>
          <Typography 
            id="joke-modal-title" 
            variant="h6" 
            component="h2"
            sx={{ fontFamily: "'Lora', serif", mb: 2 }}
          >
            Päivän vitsi
          </Typography>
          <Typography 
            id="joke-modal-description" 
            sx={{ 
              whiteSpace: 'pre-line',
              fontFamily: "'Lora', serif"
            }}
          >
            {joke.text}
          </Typography>
        </Box>
      </Modal>
    </Box>
  );
};

export default DailyJoke;